<template>
  <v-card>
    <v-card-title>
      نمای کلی از بازار
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="items" :search="search">
    </v-data-table>
  </v-card>
</template>

<script>
import axiosApi from "@/axios";
export default {
name: "SpotOrdersQuickView",
  data(){
    return {
      items: [],
      search : '',
      headers : [
        {text : 'آی دی پیر' , value : 'pair.id'},
        {text : 'نماد' , value : 'pair.symbol'},
        {text : 'بهترین قیمت خرید' , value : 'buyBestPrice'},
        {text : 'بهترین قیمت فروش' , value : 'sellBestPrice'},
        {text : 'آخریت قیمت معامله شده' , value : 'lastCompletedOrderPrice'},
        {text : 'مقدار معاملات فروش باز' , value : 'sellOpenOrdersTotalVolume'},
        {text : 'مقدار معاملات خرید باز' , value : 'buyOpenOrdersTotalVolume'},
        {text : 'متوسط زمان انتظار' , value : 'waitingInQueueAverageTime'},
      ]
    }
  },
  methods : {
    getSpotOrdersQuickView()
    {
      axiosApi().post('api/Manage/v1/Report/GetSpotOrdersQuickView')
      .then(({data})=>{
        this.items = data.data.pairs
      })
    }
  },
  mounted() {
  this.getSpotOrdersQuickView()
  }
}
</script>

<style scoped>

</style>